@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  /* Keep your existing print styles */
  body * {
    visibility: hidden;
  }

  #Overall-Container,
  #Overall-Container * {
    visibility: visible;
  }

  #Overall-Container {
    position: absolute;
    left: 0;
    top: 0;
  }

  /* Add your new page break rule without removing the existing ones */
  .print-page-break {
    page-break-before: always;
  }

  .print-avoid-break {
    page-break-inside: avoid;
  }

  /* Your existing rules for margins, hiding elements, etc., remain unchanged */
  @page {
    margin-top: 16mm;
    margin-bottom: 16mm;
    margin-left: 0mm;
    margin-right: 0mm;
  }
}

@page {
  margin-top: 16mm;
  margin-bottom: 16mm;
  margin-left: 0mm;
  margin-right: 0mm;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

/* selected dropshadow */
.inner-drop-shadow {
  filter: drop-shadow(0px 0px 10px #c8d6e1); /* Adjust values as needed */
}

.green-shadow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px; /* Width of the shadow */
  height: 40px; /* Height of the shadow */
  background-color: #dce4d5; /* Color of the shadow */
  border-radius: 50%; /* Make it circular */
  transform: translate(-50%, -50%); /* Center the pseudo-element */
  z-index: -1; /* Place it behind the image */
}

.blue-shadow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px; /* Width of the shadow */
  height: 40px; /* Height of the shadow */
  background-color: #c8d6e1; /* Color of the shadow */
  border-radius: 50%; /* Make it circular */
  transform: translate(-50%, -50%); /* Center the pseudo-element */
  z-index: -1; /* Place it behind the image */
}

/* Import Inter font with desired weights */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@layer components {
  /* Fonts start here */
  .custom-12 {
    @apply text-xs font-normal;
  }
  .custom-12-med {
    @apply text-xs font-medium;
  }
  .custom-12-semi {
    @apply text-xs font-semibold;
  }
  .custom-14 {
    @apply tablet:text-s mobile:text-s font-normal;
  }
  .custom-14-med {
    @apply tablet:text-s mobile:text-s font-medium;
  }
  .custom-14-semi {
    @apply tablet:text-s mobile:text-s font-semibold;
  }
  .custom-18 {
    @apply desktop:text-lg tablet:text-lg font-normal;
  }
  .custom-18-med {
    @apply desktop:text-lg tablet:text-lg font-medium;
  }
  .custom-18-semi {
    @apply desktop:text-lg tablet:text-lg font-semibold;
  }
  .custom-16 {
    @apply tablet:text-base mobile:text-s font-normal;
  }
  .custom-16-med {
    @apply tablet:text-base mobile:text-s font-medium;
  }
  .custom-16-semi {
    @apply tablet:text-base mobile:text-s font-semibold;
  }
  .custom-20 {
    @apply text-xl font-normal tablet:text-xl mobile:text-lg font-helvetica;
  }
  .custom-20-med {
    @apply text-xl tablet:text-xl mobile:text-lg font-medium;
  }
  .custom-20-semi {
    @apply text-xl tablet:text-xl mobile:text-lg font-semibold;
  }
  .custom-24 {
    @apply text-2xl font-semibold;
  }
  .custom-24-med {
    @apply text-2xl font-semibold;
  }
  .custom-30 {
    @apply desktop:text-3xl mobile:text-2xl font-semibold;
  }
  .custom-36 {
    @apply text-4xl font-semibold tracking-tight;
  }
  .custom-display {
    @apply text-display mobile:text-4xl  font-semibold tracking-tight;
  }

  .custom-display2 {
    @apply desktop:text-display2 tablet:text-display mobile:text-4xl tracking-tight font-semibold text-gray900;
  }

  /* Breakpoints start here */
  .show-mobile {
    @apply desktop-lg:hidden desktop:hidden tablet:hidden mobile:block;
  }
  .show-desktop {
    @apply desktop:block tablet:hidden mobile:hidden;
  }
  .show-tablet {
    @apply desktop:hidden tablet:block mobile:hidden;
  }
  .show-desktop-lg {
    @apply desktop-lg:block mobile:hidden;
  }
  .show-desktop-lg-desktop {
    @apply desktop-lg:block desktop:block mobile:hidden;
  }
  .show-mobile-tablet {
    @apply desktop:hidden tablet:block mobile:block;
  }
  .show-desktop-tablet {
    @apply desktop:block tablet:block mobile:hidden;
  }
  .show-desktop-mobile {
    @apply desktop:block tablet:hidden mobile:block;
  }
  .show-all {
    @apply desktop:block tablet:block mobile:block;
  }
  .margin-adaptive {
    @apply max-w-[1200px] desktop-lg:px-2 desktop:px-4 tablet:px-4 mobile:px-4;
  }
  /* Effects start here */
  .shadow-current-section {
    @apply shadow-lg;
  }

  /* Components start here */
  .small-button {
    @apply text-gray700 space-x-1 px-[10px] py-[6px] border rounded-lg bg-white border-borderprimary items-center custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .normal-button {
    @apply text-gray700 space-x-1 px-[14px] py-[10px] border rounded-lg bg-white border-borderprimary items-center custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }
  .cta-button {
    @apply space-x-2 px-[14px] py-[10px] border rounded-lg text-white border-sprout items-center bg-sprout custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }
  .selected-button {
    @apply space-x-2 px-[14px] py-[10px] border rounded-lg border-nafsnBlue items-center custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .secondary-button {
    @apply text-nafsnBlue space-x-2 px-[14px] py-[10px] border rounded-lg border-river border-opacity-40 items-center custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .blue-button {
    @apply space-x-2 px-[14px] py-[10px] border rounded-lg text-white border-river items-center bg-nafsnBlue custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .warning-banner {
    @apply p-3 flex space-x-4 items-center bg-white rounded-xl border shadow-sm border-bordersecondary transition-all duration-300 desktop:hover:scale-1005 desktop:hover:shadow ease-in-out;
  }

  .sidebar-button-active {
    @apply px-3 py-2 border-l-2 border-nafsnBlue custom-14-semi text-nafsnBlue flex-row flex transition-all duration-100 hover:text-nafsnBlue ease-in-out;
  }
  .sidebar-button-inactive {
    @apply px-3 py-2 border-l-2 border-white custom-14-semi text-gray500 flex-row flex transition-all duration-100 hover:text-nafsnBlue ease-in-out;
  }

  .tab-button {
    @apply px-1 pt-1 pb-3 custom-14-semi text-gray600 flex-row flex transition duration-300 ease-in-out;
    /* Other styles specific to your tab buttons */
  }

  .lg-button-cta {
    @apply px-[14px] py-[8px] border rounded-lg text-white border-river items-center bg-nafsnBlue custom-14-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .lg-button {
    @apply px-[14px] py-[8px] rounded-lg text-gray600 items-center custom-14-med flex-row flex transition-all duration-300 ease-in-out hover:custom-14-semi;
  }

  .xlg-button {
    @apply text-gray700 space-x-2 px-[22px] py-[16px] border rounded-lg bg-white border-borderprimary items-center custom-16-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .xlg-button-cta {
    @apply px-[22px] py-[16px] border rounded-lg text-white border-river items-center bg-nafsnBlue custom-16-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95;
  }

  .admin-tab {
    @apply text-gray700 space-x-2 px-[22px] py-[16px] border rounded bg-white border-borderprimary items-center w-full custom-16-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95 justify-center;
  }

  .admin-tab-cta {
    @apply text-nafsnBlue space-x-2 px-[22px] py-[16px] border rounded bg-white border-river items-center w-full custom-16-semi flex-row flex transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out active:scale-95 justify-center;
  }

  /* Active tab style, if you need to highlight the active tab */
  .tab-button.active {
    @apply text-nafsnBlue;
    /* Other styles to distinguish the active tab */
  }

  #slider {
    @apply h-[2px] bg-nafsnBlue absolute bottom-0 transition-all duration-300 ease-in-out;
    /* Set the initial width and left properties if you want, but they will be overwritten by JavaScript */
    width: 0; /* Initial width, will be set by JS */
    left: 0; /* Initial position, will be set by JS */
  }

  .rubric-tag {
    @apply custom-16-med text-gray600 w-[60px] h-full flex items-center justify-center p-2 border-borderprimary border-2 rounded-lg mr-6 mt-[2px];
  }

  .search-bar {
    @apply pl-10 px-[14px] py-[10px] mobile:flex mobile:flex-grow tablet:min-w-[400px] border border-borderprimary rounded-lg focus:outline-none focus:border-gray400 placeholder-gray500 transition duration-300 ease-in-out shadow-sm text-gray900;
  }

  .input-tag {
    @apply mb-2 text-gray700 custom-14-med tablet:w-[280px];
  }

  .input-field {
    @apply text-gray900 block w-full py-[10px] px-[14px] rounded-lg border border-borderprimary custom-16 focus:outline-none focus:border-gray400 placeholder-gray500 transition duration-300 ease-in-out shadow-sm min-w-[150px] max-w-[512px];
  }

  .pop-up-cancel {
    @apply border border-nafsnBlue custom-14-semi text-nafsnBlue transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out py-3 px-4 mobile:w-1/2 rounded-lg active:scale-95;
  }

  .pop-up-cta {
    @apply bg-nafsnBlue text-white custom-14-semi transition-all duration-300 desktop:hover:-translate-y-0.5 desktop:hover:shadow ease-in-out py-3 px-4 mobile:w-1/2 rounded-lg active:scale-95;
  }

  .activeNavLink {
    @apply text-nafsnBlue custom-14-med tablet:px-2 tablet:py-2 tablet:mx-1 bg-bghoverblue rounded;
  }
  .inactiveNavLink {
    @apply text-gray700 custom-14-med tablet:px-2 tablet:py-2 tablet:mx-1 rounded;
  }

  .guide-tab-active {
    @apply px-4 py-2 text-nafsnBlue custom-14-semi bg-bghoverblue rounded;
  }
  .guide-tab-inactive {
    @apply flex flex-row gap-2 items-center px-4 py-2 text-gray700 custom-14-med rounded hover:text-nafsnBlue bg-white hover:bg-gray200 hover:bg-opacity-50;
  }

  .reading-overall-container {
    @apply flex flex-col w-full flex-grow desktop:gap-16 mobile:gap-8 tablet:mt-8 mobile:mt-4;
  }

  .reading-sections-container {
    @apply flex flex-col desktop:gap-8 mobile:gap-6;
  }

  .reading-subsections-container {
    @apply flex flex-col gap-2;
  }
}
